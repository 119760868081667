export const programs = () => {
    return [
      {
        imgSrc: "/imgs/program1.svg",
        key: "early",
        title: "Early Math Foundations (K-3)",
        desp: " This program builds a strong math foundation, focusing on number sense, patterns, basic arithmetic, and spatial reasoning through engaging activities",
        points: [
          "Fun, interactive games.", "Hands-on manipulatives for conceptual learning.", "Small groups for personalized attention."
          ],
        goal: "To foster a positive attitude toward math, build confidence, and understand key concepts."
      },
      {
        imgSrc: "/imgs/program2.svg",
        key: "Intermediate",
        title: "Intermediate Math Mastery (Grades 4-8)",
        desp: "Reinforces core math concepts like fractions, decimals, geometry, and algebra through digital tools, real-world problems, and personalized support.",
        points: [
          "Concept-focused lessons. ", "Regular assessments to track progress.", "Homework support and study skill development."
          ],
        goal: "To strengthen problem-solving skills and prepare for high school math."
      },
      {
        imgSrc: "/imgs/program3.svg",
        key: "Advanced",
        title: "Advanced Math Skills (Grades 9-12)",
        desp: " Tailored for high school students aiming for math excellence, covering advanced algebra, calculus, and more, with a focus on analytical thinking",
        points: [
          "In-depth lessons on complex topics.", "University exam prep.", " Real-world application of math skills"
          ],
        goal: "To excel in math, prep for tests, and build a strong foundation for future studies"
      },
      {
        imgSrc: "/imgs/program5.svg",
        key: "Homework",
        title: "Homework Help & Study Space",
        desp: "We offer homework help and a quiet study space for independent work, supported by qualified educators",
        points: [
          "Homework assistance.", "Complimentary access to our computer lab.", " Peaceful, focused study environment."
          ],
        goal: "To support assignment completion and develop independent learning habits"
      }
    ]
}
export const findHereData = () => {
  return [
    {
      imgSrc: "/imgs/you-will-find-here1.png",
      title: "Worksheets",
      desp: "Carefully designed worksheets that cover various math topics for all grade levels, from Kindergarten to Grade 12. Perfect for extra practice at home or reinforcing classroom learning.",
    },
    {
      imgSrc: "/imgs/you-will-find-here2.png",
      title: "Assessments",
      desp: "Quick and easy assessments to help you gauge your child’s understanding of key math concepts and identify areas for improvement",
    },
    {
      imgSrc: "/imgs/you-will-find-here3.png",
      title: "Practice Test",
      desp: "Simulated tests to help students prepare for school exams or standardized tests, reducing anxiety and building confidence",
    },
    {
      imgSrc: "/imgs/you-will-find-here4.png",
      title: "Quizzes",
      desp: "Simulated tests to help students prepare for school exams or standardized tests, reducing anxiety and building confidence",
    },
  ]
}
export const grades = () => {
  return [
    {
      title: "Kindergarten",
      url: "https://drive.google.com/file/d/1omjimlISBA37jLroMWzB-VX9MrQwNCoq/view?usp=sharing"
    },
    {
      title: "Grade 1",
      url: "https://drive.google.com/file/d/1G6shAXXmzChaDAtE_ZNerWtCkC55wQTD/view?usp=sharing"
    },
    {
      title: "Grade 2",
      url: "https://drive.google.com/file/d/1JqM2b1mBm5ICp6zRe2zppLZkiqS5tCpo/view?usp=sharing"
    },
    {
      title: "Grade 3",
      url: "https://drive.google.com/file/d/15z8GnK3rzZZ-UaoWRElci6aO5V2A2X7e/view?usp=sharing"
    },
    {
      title: "Grade 4",
      url: "https://drive.google.com/file/d/1vqfYxwBPa_juXQjYK3kXeOEkeQsGb2Yq/view?usp=sharing"
    },
    {
      title: "Grade 5",
      url: "https://drive.google.com/file/d/1f7fvZaCGIm_5kekFgSthIWs-u85RXjFI/view?usp=sharing"
    },
    {
      title: "Grade 6",
      url: "https://drive.google.com/file/d/1SCR_xqLIM7ukawUJRHJj9-xBCy9j0Cdg/view?usp=sharing"
    },
    {
      title: "Grade 7",
      url: "https://drive.google.com/file/d/1iImtD3IjrbtVaiDSiilC5FC6OgdnENq-/view?usp=sharing"
    },
    {
      title: "Grade 8",
      url: "https://drive.google.com/file/d/17FR2RiJrKmamg38kJXAWHowBAEV4slYf/view?usp=sharing"
    },
    {
      title: "Grade 9",
      url: "https://drive.google.com/file/d/15x8qNNr1KKY0Q7JYv1MDK9-K56WLAJpc/view?usp=sharing"
    },
    {
      title: "Grade 10",
      url: "https://drive.google.com/file/d/1VZan0oekYs5fmgfdIdAXpwOwKUAG9jmy/view?usp=sharing"
    },
    {
      title: "Grade 11",
      url: "https://drive.google.com/file/d/1usEFYl_B50KT4rqOhRJEX-Rpw7yRu14J/view?usp=sharing"
    },
    {
      title: "Grade 12",
      url: "https://drive.google.com/file/d/1abHOSdxPIcBqjVJ7BIuOxVpO7SslF38b/view?usp=sharing"
    },
  ]
}
export const getOurApproachData = () => {
  return [
    {
      icon: "fas fa-clipboard-list",
      title: "Personalized Learning Plans",
      description: "We assess each student's strengths and areas for improvement, creating a customized learning plan that meets their specific needs."
    },
    {
      icon: "fas fa-user-graduate",
      title: "Experienced Educators",
      description: "Our team consists of passionate and skilled educators who are committed to making math engaging and accessible for all students."
    },
    {
      icon: "fas fa-hands-helping",
      title: "Supportive Environment",
      description: "We offer a welcoming space where students feel safe to ask questions, make mistakes, and grow at their own pace."
    }
  ]
}