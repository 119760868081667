import React from 'react'
import { Link } from 'react-router-dom';
import { findHereData, grades } from '../dataHelp';
import Footer from './presentational/Footer';
import Header from './presentational/Header';

function Resources() {
  const BG_IMAGE = `url(/imgs/section-bg.png)`;
  const bannerStyles = {
    backgroundImage: BG_IMAGE,
    backgroundPosition: "center",
    backgroundSize: "cover",
    minHeight: '510px'
  }
  const getBannerCont = () => {
    return (
      <>
        <h1 className="font-bold mt-5 pt-4 font-60 text-primary"><small className="text-dark font-25">At</small> Equation Hub,</h1>
        <p className="mt-2 font-25 pe-4">we believe that every child deserves access to the right tools to excel in math, regardless of their background or circumstances. That’s why we’ve created this Resources page a collection of free worksheets, assessments, practice tests, and quizzes designed to help parents and students alike.</p>
      </>
    )
  }
  const getBannerSection = () => {
    return (
      <>
        <div className="home-banner-cont pb-5 position-relative" style={bannerStyles}>
          <div className="row justify-content-end">
            <div className="col-md-6 ps-0 pe-5">
              {getBannerCont()}
            </div>
            <div className="col-md-5">
              <img src="/imgs/resources-hero-banner.png" width="100%"/>
            </div>
          </div>
          <div className="text-center px-5">
              <img src="/imgs/resources-hero-banner2.svg" width="100%" />
          </div>
        </div>
      </>
    )
  }
  const getProgramCard = (program, index) => {
    return (
      <div key={program.title} className="col-md-6 col-8 mt-3">
        <div className="find-here-card">
          <div className={`row ${index % 2 != 0 ? 'flex-row-reverse' : ''}`}>
            <div className="col-md-8 px-4 py-4 text-center">
              <p className="mt-3 font-25 font-bold">{program.title}</p>
              <p className="font-18 desp five-liner text-dark">{program.desp}</p>
            </div>
            <div className="align-self-end col-md-4 px-0">
              <img src={program.imgSrc} width="100%" />
            </div>
          </div>
        </div>
    </div>
    )
  } 
  const getGradeBtn = (grade) => {
   return <a href={grade.url} target="_blank" rel="noopener noreferrer" key={grade.title} className="gradeBtn">{grade.title}</a>
  }
  const getGradesSection = () => {
    return(
      <>
        <div className="row justify-content-center pb-5">
          <div className="col-md-8 px-0 text-center">
            <h2 className="font-bold font-50">Our Commitment to the Community</h2>
            <p className="mb-2 mt-3 font-18 px-4">We provide these resources as a token of appreciation to our community and to support parents — even those who are not currently part of our program. We believe that everyone should have access to quality educational tools that empower them to excel in math or any subject.</p>
          </div>
          <div className="col-md-8 px-0 text-center mt-5">
            <h2 className="font-bold font-50">Need More Support? We’re Here to Help!</h2>
            <p className="mb-2 mt-3 font-18 px-4">If you find these resources helpful and would like more personalized guidance, consider <Link className="text-primary font-bold" to={"/registration"}>scheduling an assessment with us.</Link> We are more than happy to evaluate your child's math level and offer tailored support to guide them towards success. Contact us today to learn more about how we can help your child thrive in math</p>
          </div>
          <div className="col-md-9 mt-4">
            <div className="note-card text-center p-3">
              <p className="font-bold font-18 mb-2">Note</p>
              <p className="font-18 mb-0">Every grade would have a link that takes the user to a another page. They would have access to the page and the page would have link to download worksheets, assessments, practice test, and quizzes to work on them</p>
            </div>
          </div>
          <div className="col-md-11 px-0 mt-3">
            <div className="d-flex flex-wrap justify-content-center">
              {grades().map((grade) => getGradeBtn(grade) )}
            </div>
          </div>
        </div>
      </>
    )
  }
  const getProgramSection = () => {
    return(
      <div className="program-sect-cont position-relative mt-4 pb-5">
        <h2 className="font-bold font-50 text-center">What You’ll Find Here</h2>
        <div className="row justify-content-center">
          <div className="col-md-11 px-0">
            <div className="row programs-cont">
              {findHereData().map((program, index) => getProgramCard(program, index) )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <Header />
      {getBannerSection()}
      {getProgramSection()}
      {getGradesSection()}
      <Footer/>
    </div>
  )
}

export default Resources