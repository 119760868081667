import React from 'react'
import '../../styles/navbar.scss';
import { NavLink } from "react-router-dom";
function Header() {
  return (
    <>
    <nav className="navbar navbar-expand-md navbar-dark shadow-sm sticky-top pb-0">
        <NavLink exact to="/" className="navbar-brand ms-4 d-flex align-items-center me-3">
            <img src="/imgs/logo2.png" height="70px" alt="Logo" />
            <h3 className="text-primary font-bold mb-0 ms-2">Equation Hub</h3>
        </NavLink>
        <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            aria-controls="collapsibleNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav text-center ms-auto me-4 main">
            <li className="nav-item px-3 pointer">
                <NavLink
                exact
                to="/"
                className="nav-link"
                activeClassName="active"
                >
                Home
                </NavLink>
            </li>
            <li className="nav-item px-3 pointer">
                <NavLink
                to="/resources"
                className="nav-link"
                activeClassName="active"
                >
                Resources
                </NavLink>
            </li>
            <li className="nav-item px-3 pointer">
                <NavLink
                to="/aboutus"
                className="nav-link"
                activeClassName="active"
                >
                About Us
                </NavLink>
            </li>
            <li className="nav-item px-3 pointer ms-5">
                <NavLink  as="button" to="/registration" className="btn contantBtn px-3 py-2">Register Now</NavLink>
            </li>
            </ul>
        </div>
    </nav>
    </>
  )
}

export default Header