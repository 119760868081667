import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className="footer row justify-content-between pt-4 px-4 mt-3 shadow-lg font-14">
        <div className="col-md-3">
            <img src="/imgs/logo2.png" className="mb-3" height="80px" alt="Logo" />
            <a className="text-dark font-bold text-decoration-none mb-3 d-flex" href="https://www.google.com/maps/place/Equation+Hub/@44.009253,-79.4739839,17z/data=!4m6!3m5!1s0x882ad18f9dd87431:0x9d62cdfa8c00f5a0!8m2!3d44.009253!4d-79.471409!16s%2Fg%2F11wc0dn116?entry=ttu&g_ep=EgoyMDI0MTAwMS4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                2 Orchard Heights Blvd Unit 31,<br/> Aurora, ON L4G 6T5
            </a>
            <p className="text-dark font-bold">equationhub.info@gmail.com</p>
            <p className="text-dark font-bold">(905) 409-6284</p>
        </div>
        <div className="col-md-7 px-0">
            <div className="row">
                <div className="col-md-6 px-0">
                    <div className="row">
                        <div className="col-md-8">
                            <p className="font-bold mb-4">Company</p>
                            <Link to="/aboutus" className="text-dark text-decoration-none mb-3 d-flex">About</Link>
                            <Link to="/resources" className="text-dark text-decoration-none mb-3 d-flex">Resources</Link>
                            <Link to="/registration" className="text-dark text-decoration-none mb-3 d-flex">Registration</Link>
                            <p>Contact us</p>
                        </div>
                        <div className="col-md-4">
                            <p className="font-bold mb-4">Legal</p>
                            <p>Privacy</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <p>Subscribe to our newsletter to receive the latest updates and information about Kodehauz.</p>
                    <div className="input-group input-group-lg">
                        <input type="text" className="form-control font-14" placeholder="Email Address" />
                        <button className="btn contantBtn px-4 py-2 font-18 font-bold" type="button" id="button-addon2"><span className="fa fa-arrow-right"></span></button>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-12 px-0 text-center p-3 border-top">
            <p className="mb-0 font-bold">All rights reserved (c) 2024 Equation Hub</p>
        </div>
    </div>
  )
}

export default Footer