import React from 'react'
import { useHistory } from 'react-router-dom';

function PromotionalModal({modalBtn}) {
  const history = useHistory();

  const navigateToReg = () => {
    history.push({
      pathname: "/registration"
    })
  }
  return (
    <>
      <a className="btn btn-primary" ref={modalBtn} data-bs-toggle="modal" href="#promotionalModal" style={{display: "none"}} role="button">Open first modal</a>
      <div className="modal fade" id="promotionalModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <img src='imgs/promotional.jpg' data-bs-dismiss="modal" style={{cursor: "pointer"}} onClick={navigateToReg} width="100%"/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PromotionalModal;
