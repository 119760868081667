import React from 'react';

const SuccessModal = ({ show, handleClose }) => {
  return (
    <div 
        className={`modal fade ${show ? 'show ' : ''}`} 
        style={{ display: show ? 'block' : 'none' }} 
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1">        
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Success</h5>
            </div>
            <div className="modal-body">
                <p>Your form has been successfully submitted!</p>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-success" onClick={handleClose}>
                    Done
                </button>
            </div>
            </div>
        </div>
    </div>
  )

};

export default SuccessModal;
