import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Footer from './presentational/Footer';
import Header from './presentational/Header';
import SuccessModal from './presentational/SuccessModel';

const Registration = () => {
  const history = useHistory();
  const BG_IMAGE = `url(/imgs/section-bg.png)`;
  const bannerStyles = {
    backgroundImage: BG_IMAGE,
    backgroundPosition: "center",
    backgroundSize: "cover",
    minHeight: '510px'
  }
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    studentFullName: '',
    studentDob: '',
    studentGrade: '',
    studentSchoolName: '',
    parentFullName: '',
    parentRelationship: '',
    parentPhone: '',
    parentEmail: '',
    tutoringSchedule: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }; 
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      studentFullName: document.getElementById('studentFullName').value,
      studentDob: document.getElementById('studentDob').value,
      studentGrade: document.getElementById('studentGrade').value,
      studentSchoolName: document.getElementById('studentSchoolName').value || '',
      parentFullName: document.getElementById('parentFullName').value,
      parentRelationship: document.getElementById('parentRelationship').value,
      parentPhone: document.getElementById('parentPhone').value,
      parentEmail: document.getElementById('parentEmail').value,
      tutoringSchedule: document.getElementById('tutoringSchedule').value,
    };
    submitForm(formData).then(() => {
      setShowModal(true);
    }).catch((error) => {
      console.error('Error submitting form', error);
    }).finally(() => {
      setLoading(false);
    });
  };
  
  const submitForm = async (data) => {
    const url = 'https://script.google.com/macros/s/AKfycbwfYqCqUEYWyCBHok7N5WTMp67gKYINATDoJBh6EmKb0IHgV2WWCzatTrMlePbF5CQrqQ/exec';
    
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'no-cors', 
      });
      return Promise.resolve();  // Resolve the promise as a success case.
    } catch (error) {
      return Promise.reject(error);  // Fail if there's an actual issue.
    }
  };   
  const getRegistrationForm = () => {
  return (
    <div style={bannerStyles}>
      <h1 className="font-bold text-center text-primary pt-4">Registration Form</h1>
      <div className="row justify-content-center mt-5">
        <div className="col-md-8">
          <form onSubmit={handleSubmit}>
            {/* Student Information Section */}
            <section className="mb-4">
              <h3 className="px-2 ms-1 mb-4">Student Information</h3>
              <div className="row mb-3">
                <div className="col-md-6 mb-3">
                  <label htmlFor="studentFullName" className="form-label">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="studentFullName"
                    name="studentFullName"
                    value={formData.studentFullName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="studentDob" className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    id="studentDob"
                    name="studentDob"
                    value={formData.studentDob}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="studentGrade" className="form-label">Grade Level</label>
                  <select
                    className="form-select"
                    id="studentGrade"
                    name="studentGrade"
                    value={formData.studentGrade}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Grade Level</option>
                    <option value="Kindergarten">Kindergarten</option>
                    <option value="Grade 1">Grade 1</option>
                    <option value="Grade 2">Grade 2</option>
                    <option value="Grade 3">Grade 3</option>
                    <option value="Grade 4">Grade 4</option>
                    <option value="Grade 5">Grade 5</option>
                    <option value="Grade 6">Grade 6</option>
                    <option value="Grade 7">Grade 7</option>
                    <option value="Grade 8">Grade 8</option>
                    <option value="Grade 9">Grade 9</option>
                    <option value="Grade 10">Grade 10</option>
                    <option value="Grade 11/12">Grade 11/12</option>
                  </select>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="studentSchoolName" className="form-label">School Name (optional)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="studentSchoolName"
                    name="studentSchoolName"
                    value={formData.studentSchoolName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </section>

            {/* Parent/Guardian Information Section */}
            <section className="mb-4">
              <h3 className="px-2 ms-1 mb-4">Parent/Guardian Information</h3>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="parentFullName" className="form-label">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="parentFullName"
                    name="parentFullName"
                    value={formData.parentFullName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="parentRelationship" className="form-label">
                    Relationship to Student
                  </label>
                  <select
                    className="form-control"
                    id="parentRelationship"
                    name="parentRelationship"
                    value={formData.parentRelationship}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Relationship</option>
                    <option value="mother">Mother</option>
                    <option value="father">Father</option>
                    <option value="guardian">Guardian</option>
                    <option value="brother">Brother</option>
                    <option value="sister">Sister</option>
                    <option value="aunt">Aunt</option>
                    <option value="uncle">Uncle</option>
                    <option value="grandparent">Grandparent</option>
                    <option value="other">Other</option>
                  </select>
                  {formData.parentRelationship === "other" && (
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Specify Relationship"
                      name="otherRelationship"
                      value={formData.otherRelationship}
                      onChange={handleChange}
                    />
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="parentPhone" className="form-label">Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="parentPhone"
                    name="parentPhone"
                    maxLength="11"
                    value={formData.parentPhone}
                    onChange={handleChange}
                    required
                    pattern="^(1)?[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4}$"
                    title="Please enter a valid Canadian phone number (10 digits or 11 digits with country code, e.g., 2345678901 or 12345678901)"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="parentEmail" className="form-label">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="parentEmail"
                    name="parentEmail"
                    value={formData.parentEmail}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <label htmlFor="tutoringSchedule" className="form-label">Preferred Tutoring Schedule (Days of the week, Times)</label>
                  <textarea
                    className="form-control"
                    id="tutoringSchedule"
                    name="tutoringSchedule"
                    rows="3"
                    value={formData.tutoringSchedule}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
             
            </section>

            <div className="px-2 mx-1 text-center">
              <button type="submit" className="btn contantBtn px-4 py-2 font-18 font-bold" disabled={loading}>
                {loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    Loading...
                  </>
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  }
  const handleModalDone = () => {
    setShowModal(false);
    history.push(
      {pathname: "/"}
    )
  }
  return(
    <>
      <Header />
      {getRegistrationForm()}
      <Footer />
      <SuccessModal show={showModal} handleClose={handleModalDone} />
      {showModal && <div className="modal-backdrop fade show"></div>}
    </>
  )
};

export default Registration;
