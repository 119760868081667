import React from 'react';
import './App.css';
import { Router, Switch, Route, useHistory } from "react-router-dom";
import Home from "./components/Home";
import Resources from './components/Resources';
import Registration from './components/Registration';
import ScrollToTop from './components/ScrollToTop';
import Aboutus from './components/Aboutus';



function App() {
  let history = useHistory();

  return (
    <Router history={history}>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/resources" component={Resources} />
        <Route path="/registration" component={Registration} />
        <Route path="/aboutus" component={Aboutus} />
      </Switch>
    </Router>
  );
}

export default App;
